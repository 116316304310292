import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "core-js/stable";
import "regenerator-runtime/runtime";
import store from "./store";
import router from "./router";

import VeeValidate, { Validator } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VeeValidateMessagesFR from "vee-validate/dist/locale/fr";

Validator.localize({ fr: VeeValidateMessagesFR });
Vue.use(VeeValidate, { locale: "fr" });
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
