export default {
  namespaced: true,
  state: {
    ref: "",
    yearInscription: null,
    saison: "",
    // yearInscription: 2022,
    // saison: "2022-2023",
    modal: false,
    modalContent: {
      titre: "",
      corps: "",
    },
    tabs: "tabs-profil",
    user: null,
    userAccount: [],
    userInfo: {},
    userPaiement: {},
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
    getRef: (state) => {
      return state.ref;
    },
    getYearInscription: (state) => {
      return state.yearInscription;
    },
    getSaison: (state) => {
      return state.saison;
    },
    getModal: (state) => {
      return state.modal;
    },
    getModalContent: (state) => {
      return state.modalContent;
    },
  },
  actions: {
    setUserPaiement({ commit }, datas) {
      commit("SET_USER_PAIEMENT", datas);
    },
    setUserInfo({ commit }, datas) {
      commit("SET_USER_INFO", datas);
    },
    setUserAccount({ commit }, datas) {
      commit("SET_USER_ACCOUNT", datas);
    },
    setUser({ commit }, datas) {
      commit("SET_USER", datas);
    },
    setModelModal({ commit }, datas) {
      commit("SET_MODEL_MODAL", datas);
    },
    setModalContent({ commit }, datas) {
      commit("SET_MODAL_CONTENT", datas);
    },
    setConfigInscription({ commit }, datas) {
      commit("SET_CONFIG_INSCRIPTION", datas);
    },
  },
  mutations: {
    SET_USER_PAIEMENT(state, datas) {
      state.userPaiement = datas;
    },
    SET_USER_INFO(state, datas) {
      state.userInfo = datas;
    },
    SET_USER_ACCOUNT(state, datas) {
      state.userAccount = datas;
    },
    SET_USER(state, datas) {
      state.user = datas;
    },
    SET_CONFIG_INSCRIPTION(state, datas) {
      state.yearInscription = datas.yearInscription;
      state.saison = datas.saison;
      state.ref = datas.ref;
    },
    SET_MODEL_MODAL(state, datas) {
      state.modal = datas;
    },
    SET_MODAL_CONTENT(state, datas) {
      if (datas == 1) {
        state.modalContent.titre = "Règlement intérieur du taekwondo élite";
        state.modalContent.corps = `<strong>Le Taekwondo ne se limite pas à entraîner son corps, le pratiquant se doit de respecter les règles suivantes :</strong><br>
        <br>
        - Importance de la courtoisie (Respect de la salle, salut du Professeur et des partenaires).<br>
        - Tenue correcte (Dobok propre et repassé).<br>
        - Attitude correcte lors des entraînements : ponctualité et assiduité aux entraînements.<br>
        - Au cours des entraînements, vous devez toujours chercher à progresser sincèrement.<br>
        <br>
        Je m’engage à ne pas utiliser les techniques qui me sont enseignées dans un but illégal et je dégage le club de toutes responsabilités en cas d’accident à l’extérieur du club.<br>
        <br>
        <ol>
          <li>        Je porte respectueusement mon Dobok et ma ceinture, que j’arrange si nécessaire, dos au Sabeumnim.
          </li>
          <li>J’éteins mon TÉLÉPHONE pendant l’entraînement.
          </li>
          <li>Je n’ai pas le droit de DISCUTER pendant les entraînements.
          </li>
          <li>Le combat libre n’est pas autorisé SANS PERMISSION du Sabeumnim.
          </li>
          <li>Je n’ai pas le droit de QUITTER le cours SANS L’AUTORISATION du Sabeumnim.
          </li>
          <li>Je me TIENS CORRECTEMENT dans le Dojang:<br>
            - Je ne m’appuie pas contre les murs<br>
            - Je ne suis jamais avachi<br>         
            - Je n’ai pas le droit de m’allonger
          </li>
          </li>
          <li>Je ne MANGE PAS et ne mâche pas de CHEWING-GUM dans le Dojang.
          </li>
          <li>Je fais attention à mon HYGIÈNE et pense à couper mes ONGLES des mains et des pieds pour ne pas blesser les autres partenaires.
          </li>
          <li>Je ne porte pas de BIJOUX pendant les entraînements.
          </li>
          <li>Je n’amène pas d’OBJETS DE VALEURS au Dojang (bijoux, etc…).
          </li>
          <li>Je range mes CHAUSSURES et mes VÊTEMENTS.
          </li>
          <li>Je respecte les HEURES D’ENTRAÎNEMENT.
          </li>
          <li>En cas d’absence, j’AVERTIS mon enseignant.
          </li>
          <li>Je n’ai pas le droit de participer à des stages, compétitions ou ENTRAÎNEMENTS dans un autre club SANS AUTORISATION de mon Sabeumnim.
          </li>
          <li>Aucune cotisation ne sera remboursée.
          </li>
          <li>Je dois remettre un certificat médical d’aptitude à la pratique du Taekwondo, pour chaque saison.
          </li>
          <li>Je m’engage à réaliser les démarches nécessaires auprès de la FFTDA pour obtenir ma licence.
          </li>
          <li>En cas de non-respect du règlement, des lieux ou des personnes, des sanctions seront prises : <br>
          - Avertissement<br> 
          - Exclusion temporaire<br> 
          - Exclusion définitive
          </li>
        </ol>
<br>
<strong>Rôle des ceintures noires:</strong><br>
<br>
C’est à partir de ce grade, que vous devenez un pratiquant effectif de l’Art Martial.<br>En plus de la dimension physique, votre formation va maintenant aborder l’aspect éducatif et philosophique de la pratique.<br>
<br>

<ol>
  <li>En cas d’absence à l’entraînement, j’avertis impérativement mon Sabeumnim.
  </li>
  <li>Si l’enseignant vous confie un cours ou une partie du cours, vous devez l’organiser de la même façon que lui.
  </li>
  <li>La modestie est une qualité primordiale d’une ceinture noire, qui doit s’employer à aider aux progrès des autres pratiquants.
  </li>
  <li>Votre position intermédiaire entre les élèves et le Sabeumnim doit vous inciter à créer une ambiance conviviale au sein du club.
  </li>
  <li>Les ceintures noires obtenues par les mineurs ne sanctionnent qu’un niveau technique et ne confèrent aucune autorité.
  </li>
  <li>Seul le Sabeumnim peut désigner une ceinture noire comme assistant.
  </li>
</ol>
<br>
<strong>À l’attention des parents:</strong><br>
<br>
- Pour les mineurs, il est demandé à l’accompagnateur de s’assurer de la présence de l’enseignant avant de laisser les enfants.<br>
- Aucune personne n’est autorisé à assister ou entrer dans la salle durant un entraînement sans y être autorisé par l’enseignant.<br>
- Le club décline toutes responsabilités concernant la perte des effets personnels.`;
      }
      if (datas == 2) {
        state.modalContent.titre = "Informations légales taekwondo élite Cergy";
        state.modalContent.corps = `
        Identité: Taekwondo élite Cergy<br>
        Adresse: <br>
        Numéro SIRET:<br>
        Mail: <br>
        numéro de téléphone :<br>
        site internet: https://www.taekwondoelite.fr<br>
        <br>
        Identité de l'hébergeur:<br>
          Nom: OVH<br>
          Adresse: 2 Rue Kellermann 59100 ROUBAIX<br>
        <br>
        
        Caractéristiques essentielles des services :  <br>l'association sportive taekwondo élite caractérise essentielles des services et leurs respectifs mis à disposition de l'acheteur sur le site internet de l'association. le client et futur licencié atteste avoir reçu un détatil de sa pré-inscription. l'association s'engage a honorer le service dans la limite des places disponibles uniquement.A défaut, le vendeur en informe le client. Ces informations sont présentées en détail et en langue française. Conformément à la loi française, elles font l'objet d'un récapitulatif et d'une confirmation lors de la validation de la pré-inscription. La durée de la validité d'un service est de une saison sportive. Tous les prix indiqué sur la plateforme sont en TTC.
        <br><br>
        clause de reserve: <br>
        les services fourni par l'association prendra effet des le paiement complet du prix de la cotisation annuelle
        <br><br>
        Paiement:<br>
        Le paiement de l'inscription s'effectue au près de l'association lors des permanences mis en place pour cette occasion.le client peut effectuer le règlement, en espèce, par chèque bancaire, chèque ANCV et coupon sport.`;
      }
    },
  },
};
