import { mapState, mapGetters, mapActions } from "vuex";
import Inscriptions from "@/services/Inscription.js";

export default {
  // data: () => {
  //   return {
  //     //Information Personnel
  //     civiliteEnfant: "",
  //     nameEnfant: "",
  //     firstnameEnfant: "",
  //     birthdateEnfant: "",

  //     // Justif data
  //     fileDataJustifEnfant: "",
  //     fileDataPictureEnfant: "",

  //     // Niveau
  //     levelEnfant: "",

  //     // Créneau enfants
  //     creneauEnfant: [],
  //   };
  // },
  // watch: {
  //   levelEnfant(newEl) {
  //     console.log("new", newEl);
  //   },
  // },
  methods: {
    // reset() {
    //   Object.assign(this.$data, this.$options.data.apply(this));
    // },

    ...mapActions("Inscription", ["setConfigInscription"]),

    getLevelByRef(i = null, item = "") {
      if (this.type == "enfant")
        if (i == 0) {
          return this.getLevelDataEnfant.find((el) =>
            this.personnalInformationEnfant_[i].level.includes(el.ref)
          );
        } else {
          return this.getLevelDataEnfant.find((el) =>
            this.enfantBlock[i - 1]["personnalInformationEnfant_"][
              i
            ].level.includes(el.ref)
          );
        }

      if (this.type == "adulte") {
        return this.getLevelDataAdulte.find(
          (el) => el.ref == item
          // this.getSaveInscriptionAdulte.niveau.includes(el.ref)
        );
      }
    },

    async getInscription() {
      // console.log("service Inscription", Inscriptions.getActivate());
      let config = await Inscriptions.getActivate();
      this.setConfigInscription({
        yearInscription: config.yearInscription,
        saison: config.saison,
        ref: config.ref,
      });
    },

    invalidDisabledBtn() {
      let invalid = false;
      // console.log("enfantBlock.length", this.enfantBlock.length);
      for (let i = 0; i < this.enfantBlock.length; i++) {
        if (this.enfantBlock[i]["personnalInformationEnfant_"][i + 1].show) {
          // this.invalid ||=
          invalid ||=
            this.enfantBlock[i]["personnalInformationEnfant_"][i + 1].invalid;
        }
      }
      // console.log("invalid enfant view", this.invalid);
      this.getIncrementblockEnfant.invalid = invalid;
    },

    findCreneauByLevel(horaire, select, type) {
      if (typeof select == "string") {
        return [...horaire].filter((el) => el.ref_niveau == select);
      }
      // if (typeof select == "string") {
      //   return [...horaire].filter((el) => el.niveau == select);
      // }
      if (Array.isArray(select)) {
        if (type == "enfant") {
          // console.log("horaire", horaire);
          // console.log("select", select);
          return [...horaire].filter((el) => {
            return [...select].includes(`${el.ref_niveau}`);
          });
        }
        // if (type == "enfant") {
        //   return [...horaire].filter((el) =>
        //     [...select].includes(
        //       `${el.niveau} ${el.startAge}/${el.endAge} ans (${
        //         this.getYearInscription - el.startAge
        //       }/${this.getYearInscription - el.endAge})`
        //     )
        //   );
        // }

        if (type == "adulte") {
          // let arrayLevel = [];
          // this.getHoraireDataAdulte.forEach((el) => {
          //   if (select.includes(`${el.niveau}`)) {
          //     let levelFind = horaire.find(
          //       (element) => element.niveau == `${el.niveau}`
          //     );
          //     arrayLevel.push(levelFind);
          //   }
          // });
          // return arrayLevel;
          // console.log("horaire adulte");
          return [...horaire].filter((el) => {
            return [...select].includes(`${el.ref_niveau}`);
          });
        }
      }
    },
    findCreneauByAllLevel(Level, select, type) {
      if (typeof select == "string") {
        return [...Level].filter((el) => el.ref == select);
      }

      if (Array.isArray(select)) {
        if (type == "enfant") {
          // console.log("Level", Level);
          // console.log("select", select);
          return [...Level].filter((el) => {
            return [...select].includes(`${el.ref}`);
          });
        }

        if (type == "adulte") {
          // console.log("horaire adulte");
          return [...Level].filter((el) => {
            return [...select].includes(`${el.ref}`);
          });
        }
      }
    },

    findLocationByRef(horaire, niveau, select, type) {
      if (type == "enfant") {
        // if (creneauHoraire.length) {
        //   return creneauHoraire.filter((el) => {
        //     el.days = el.days.filter((val) => select.includes(val.ref));
        //     return el.days.filter((val) => select.includes(val.ref));
        //   });
        // } else {
        //   return [];
        // }

        // // GOOD 2
        let creneau = JSON.parse(JSON.stringify(horaire));
        let creneauHoraire = creneau.filter((el) => {
          return el.ref_niveau == niveau;
          // return (
          //   `${el.niveau} ${el.startAge}/${el.endAge} ans (${
          //     this.getYearInscription - el.startAge
          //   }/${this.getYearInscription - el.endAge})` == niveau
          // );
        });

        // console.log("creneau", creneauHoraire);

        if (creneauHoraire.length) {
          return creneauHoraire.filter((el) => {
            el.days = el.days.filter((val) => select.includes(val.ref));
            return el.days.filter((val) => select.includes(val.ref));
          });
        } else {
          return [];
        }
        //   // GOOD 2

        // GOOD
        // if (creneauHoraire.length) {
        //   // if (creneau.length) {
        //   let creneauFilter = [...creneauHoraire].filter((el) => {
        //     // let creneauFilter = [...creneau].filter((el) => {
        //     // el.days = el.days.filter((val) => select.includes(val.ref));
        //     // el.days = el.days.slice().filter((val) => select.includes(val.ref));
        //     return el.days.filter((val) => [...select].includes(val.ref));

        //     //return el.days.filter((val) => select.includes(val.ref));
        //   });

        //   creneauFilter.forEach((el) => {
        //     // creneauResult.forEach((el) => {
        //     el.days = el.days.filter((val) => [...select].includes(val.ref));
        //     // el.days = el.days.filter((val) => select.includes(val.ref));
        //   });

        //   return creneauFilter;
        // }
        // GOOD
      }
    },

    findCreneauByRef(horaire, niveau, select, type) {
      if (type == "adulte") {
        let creneau = [...horaire].filter((el) => el.niveau == niveau);

        if (creneau.length)
          return creneau.filter((el) => select.includes(el.ref));
      }

      if (type == "enfant") {
        let creneau = [...horaire].filter(
          (el) =>
            `${el.niveau} ${el.startAge}/${el.endAge} ans (${
              this.getYearInscription - el.startAge
            }/${this.getYearInscription - el.endAge})` == niveau
        );

        // console.log("creneau", creneau);

        if (creneau.length) {
          // //OLD
          // console.log(
          //   "creneau mixim",
          //   creneau.filter((el) =>
          //     el.days.filter((val) => select.includes(val.ref))
          //   )
          // );
          // return creneau.filter((el) =>
          //   el.days.filter((val) => select.includes(val.ref))
          // );
          // // OLD
          // GOOD
          let arrayCreneau = [];
          creneau.forEach((val) => {
            let creneauFilter = val.days.filter((el) =>
              select.includes(el.ref)
            );
            creneauFilter.forEach((element) => {
              arrayCreneau.push(element);
            });
          });
          // for (let i = 0; i < creneau.length; i++) {
          //   let creneauFilter = creneau[i].days.filter((el) =>
          //     select.includes(el.ref)
          //   );
          //   creneauFilter.forEach((element) => {
          //     arrayCreneau.push(element);
          //   });
          // }
          // console.log("arrayCreneau", arrayCreneau);
          return arrayCreneau;
          // GOOD
          // OLD
          // return creneau.filter((el) =>
          //   el.days.filter((val) => select.includes(val.ref))
          // );
          // OLD
        }
      }
    },

    findPriceByLevel(type, price, select, nb, max) {
      let SelectPrice = [];

      if (type === "adulte") {
        SelectPrice = price.filter((el) => el.ref_niveau == select);
        // SelectPrice = price.filter((el) => el.niveau == select);
      }

      if (type === "enfant") {
        SelectPrice = price.filter(
          (el) => el.ref_niveau == select
          // (el) =>
          //   `${el.niveau} ${el.startAge}/${el.endAge} ans (${
          //     this.getYearInscription - el.startAge
          //   }/${this.getYearInscription - el.endAge})` == select
        );
      }

      // console.log("SelectPrice", SelectPrice);

      let t = SelectPrice.find((el) => el.NbTrainingByWeek == nb);
      if (t) {
        if (t) {
          return t.price;
        }
      } else {
        let recursifTarif = (int) => {
          let total = SelectPrice.find((el) => el.NbTrainingByWeek == nb + int);

          if (total) {
            return total.price;
          }
        };

        let tarif = null;
        for (let i = 0; i <= max; i++) {
          // console.log("i " + i, recursifTarif(i));
          if (recursifTarif(i)) {
            tarif = recursifTarif(i);
            break;
          }
        }
        // console.log("tarif", tarif);
        return tarif;
      }
    },
    maxTraining(type, i = null, level = null) {
      let arrayLevel = [];
      if (type == "adulte") {
        arrayLevel = this.findCreneauByAllLevel(
          // arrayLevel = this.findCreneauByLevel(
          this.getLevelDataAdulte,
          this.getSaveInscriptionAdulte.niveau,
          type
        );

        // console.log("max", arrayLevel);
      }
      if (type == "enfant") {
        if (i === 0) {
          arrayLevel = this.findCreneauByAllLevel(
            // arrayLevel = this.findCreneauByLevel(
            this.getLevelDataEnfant,
            this.personnalInformationEnfant_[i].level,
            type
          );
        } else {
          arrayLevel = this.findCreneauByAllLevel(
            // arrayLevel = this.findCreneauByLevel(
            this.getLevelDataEnfant,
            this.enfantBlock[i - 1]["personnalInformationEnfant_"][i].level,
            type
          );
        }
      }
      // console.log("maxTraining", arrayLevel);
      if (Array.isArray(arrayLevel) && arrayLevel.length) {
        // console.log("lev", level);

        let maxTrainingByWeek = null;

        if (level) {
          const arrayLevelFind = arrayLevel.find((el) => el.ref == level);
          maxTrainingByWeek =
            arrayLevelFind.NbTrainingByWeek[
              arrayLevelFind.NbTrainingByWeek.length - 1
            ];
        } else {
          maxTrainingByWeek =
            arrayLevel[0].NbTrainingByWeek[
              arrayLevel[0].NbTrainingByWeek.length - 1
            ];
        }
        return maxTrainingByWeek;
      }
    },
    minTraining(type, i, index = 0) {
      let arrayLevel = [];
      if (type == "adulte") {
        this.getHoraireDataAdulte.forEach((el) => {
          if (
            this.getSaveInscriptionAdulte.niveau.includes(`${el.ref_niveau}`)
          ) {
            let levelFind = this.getLevelDataAdulte.find(
              (element) => element.ref == `${el.ref_niveau}`
              // (element) => element.niveau == `${el.ref_niveau}`
              // (element) => element.niveau == `${el.niveau}`
            );
            arrayLevel.push(levelFind);
          }
        });
      }
      if (type == "enfant") {
        if (i === 0) {
          this.getHoraireDataEnfant.forEach((el) => {
            if (
              this.personnalInformationEnfant_[i].level == el.ref_niveau
              // this.getSaveInscriptionEnfant.niveau.includes(`${el.ref_niveau}`)
            ) {
              let levelFind = this.getLevelDataEnfant.find(
                (element) => element.ref == `${el.ref_niveau}`
                // (element) => element.niveau == `${el.niveau}`
              );
              arrayLevel.push(levelFind);
            }
          });

          // arrayLevel = this.findCreneauByLevel(
          //   this.getLevelDataEnfant,
          //   // this.getHoraireDataEnfant,
          //   this.personnalInformationEnfant_[i].level,
          //   type
          // );
        } else {
          this.getHoraireDataEnfant.forEach((el) => {
            if (
              this.enfantBlock[i - 1]["personnalInformationEnfant_"][i].level ==
              el.ref_niveau
              // this.getSaveInscriptionEnfant.niveau.includes(`${el.ref_niveau}`)
            ) {
              let levelFind = this.getLevelDataEnfant.find(
                (element) => element.ref == `${el.ref_niveau}`
                // (element) => element.niveau == `${el.niveau}`
              );
              arrayLevel.push(levelFind);
            }
          });
          // arrayLevel = this.findCreneauByLevel(
          //   this.getLevelDataEnfant,
          //   // this.getHoraireDataEnfant,
          //   this.enfantBlock[i - 1]["personnalInformationEnfant_"][i].level,
          //   type
          // );
        }
      }
      // console.log("minTraining", arrayLevel);
      if (Array.isArray(arrayLevel) && arrayLevel.length) {
        const minTrainingByWeek = arrayLevel[index].NbTrainingByWeek[0];

        return minTrainingByWeek;
      }
    },
  },

  computed: {
    ...mapGetters("Adulte", [
      "getLevelDataAdulte",
      "getSaveInscriptionAdulte",
      "getHoraireDataAdulte",
    ]),
    ...mapGetters("Enfant", [
      "getHoraireDataEnfant",
      "getLevelDataEnfant",
      "getSaveInscriptionEnfant",
      "getIncrementblockEnfant",
    ]),
    ...mapGetters("Inscription", ["getYearInscription"]),
    ...mapState("Enfant", ["personnalInformationEnfant_", "enfantBlock"]),
  },
};
