import axios from "axios";
import config from "../../config/config.js";
export default {
  async getActivate() {
    try {
      let getInscription = await axios.get(
        `${config.api_url}/inscription/get-activate-config`
      );
      // console.log("get inscription", getInscription);
      return getInscription.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getMembre(options) {
    try {
      let getMembreInscription = await axios.get(
        `${config.api_url}/user/get-membre`,
        { params: options }
      );
      // console.log("getMembreInscription", getMembreInscription);
      return getMembreInscription.data;
    } catch (error) {
      console.log(error);
    }
  },

  // async getCheckToken() {
  async getCheckToken(options) {
    try {
      let checkToken = await config.instance.get(`/user/membre/checkToken`, {
        params: options,
      });
      // console.log("checkToken", checkToken);
      return checkToken.data;
    } catch (error) {
      console.log(error);
    }
  },

  async postJustif(options) {
    try {
      let fd = new FormData();

      let FormData_append_object = (fd, obj, key) => {
        var i, k;
        for (i in obj) {
          k = key ? key + "[" + i + "]" : i;
          if (typeof obj[i] == "object") FormData_append_object(fd, obj[i], k);
          else fd.append(k, obj[i]);
        }
      };

      FormData_append_object(fd, options);

      options.forEach((element) => {
        fd.append("certificat", element.fileData);
      });

      let postJustif = await config.instance.post(`/piece/add`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // let postJustif = await axios.post(`${config.api_url}/piece/add`, fd, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: `${localStorage.getItem("token")}`,
      //   },
      // });

      // console.log("postJustif", postJustif);
      return postJustif.data.add;
    } catch (error) {
      console.log(error);
    }
  },

  async searchRef(options) {
    try {
      await axios.post(`${config.api_url}/search/ref`, options);
    } catch (error) {
      console.log(error);
    }
  },
};
