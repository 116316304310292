import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Inscription from "@/services/Inscription";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/enfant",
    name: "enfant",
    component: () =>
      import(/* webpackChunkName: "enfant" */ "../views/EnfantView.vue"),
  },
  {
    path: "/adulte",
    name: "adulte",
    component: () =>
      import(/* webpackChunkName: "adulte" */ "../views/AdulteView.vue"),
  },
  {
    path: "/reglement",
    name: "reglement",
    component: () =>
      import(/* webpackChunkName: "reglement" */ "../views/ReglementView.vue"),
  },
  {
    path: "/informations-legales",
    name: "infosLegales",
    component: () =>
      import(/* webpackChunkName: "infos" */ "../views/InfosLegalesView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/compte",
    name: "compte",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "compte" */ "../views/CompteView.vue"),
  },
  {
    path: "/profil",
    name: "profil",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "profil" */ "../views/ProfilCompteView.vue"),
  },
  {
    path: "/document",
    name: "document",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "document" */ "../views/DocumentCompteView.vue"
      ),
  },
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  // routes,
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["Inscription/isAuthenticated"]) {
      next();
      return;
    } else {
      let userGet = null;
      if (
        localStorage.getItem("token") &&
        localStorage.getItem("token") != ""
      ) {
        // userGet = await Inscription.getCheckToken();
        userGet = await Inscription.getCheckToken({
          token: localStorage.getItem("token"),
        });
      }

      if (userGet) {
        // console.log("store", store);
        // store.state.user = true;
        store.dispatch("Inscription/setUser", true);
        store.dispatch("Inscription/setUserAccount", userGet.allUser);
        store.dispatch("Inscription/setUserInfo", userGet.user);
        store.dispatch("Inscription/setUserPaiement", userGet.paiement);
        next();
        return;
      } else {
        next("/login");
      }
    }
  } else {
    next();
  }
});

export default router;
