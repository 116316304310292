import Vue from "vue";
export default {
  namespaced: true,
  state: {
    enfantBlock: [],
    resetEnfant: false,
    incrementEnfant: {
      init: 1,
      max: 8,
    },
    incrementblockEnfant: {
      init: 0,
      invalid: false,
    },

    invalidFormBlockEnfant: [],

    invalidFormEnfant: {
      information: true,
      piece: true,
      niveau: true,
      creneau: true,
    },

    // New
    personnalInformationEnfant_: {
      0: {
        invalid: true,
        civilite: null,
        name: "",
        firstname: "",
        birthdate: "",
        fileDataJustif: "",
        fileDataPicture: "",
        level: [],
        creneau: [],
      },
    },
    // New

    saveInscriptionEnfant: {
      cotisationTotal: null,
      // niveau: "",
      personnalInformation: {
        firstname: "",
        // civilite: "",
        // name: "",
        // birthdate: "",
      },
      //enfant: [],
      // creneau: [],
      ref: "",
      personnalInformationEnfant: {
        enfant_0: { 0: { firstname: "", name: "", level: [] } },
      },
    },
    // A generer avec export excel programme python vers JSON ou Data base
    horaireDataEnfant: [
      // {
      //   location: "Moulin à vent",
      //   adresse: "",
      //   niveau: "Babys",
      //   startAge: 5,
      //   endAge: 6,
      //   description: "description 1",
      //   ref: "MVA",
      //   readonly: false,
      //   days: [
      //     {
      //       ref: "MVA1",
      //       jour: "mardi",
      //       creneau: "18h45/19h45",
      //       description: "description day 1",
      //     },
      //     {
      //       ref: "MVA2",
      //       jour: "mercredi",
      //       creneau: "18h00/19h00",
      //       description: "description day 2",
      //     },
      //     {
      //       ref: "MVA3",
      //       jour: "jeudi",
      //       creneau: "18h00/19h00",
      //       description: "description day 3",
      //     },
      //   ],
      // },
      // {
      //   location: "Les touleuses",
      //   adresse: "",
      //   niveau: "Babys",
      //   startAge: 5,
      //   endAge: 6,
      //   description: "description 1",
      //   ref: "MVA1",
      //   readonly: false,
      //   days: [
      //     {
      //       ref: "TA1",
      //       jour: "mardi",
      //       creneau: "18h45/19h45",
      //       description: "description day 1",
      //     },
      //     {
      //       ref: "TA2",
      //       jour: "mercredi",
      //       creneau: "18h00/19h00",
      //       description: "description day 2",
      //     },
      //     {
      //       ref: "TA3",
      //       jour: "jeudi",
      //       creneau: "18h00/19h00",
      //       description: "description day 3",
      //     },
      //   ],
      // },
      // {
      //   location: "Moulin à vent",
      //   adresse: "",
      //   niveau: "Enfants",
      //   startAge: 9,
      //   endAge: 11,
      //   description: "description 1",
      //   ref: "MVA2",
      //   readonly: false,
      //   days: [
      //     {
      //       ref: "MVE1",
      //       jour: "mardi",
      //       creneau: "18h45/19h45",
      //       description: "description day 1",
      //     },
      //     {
      //       ref: "MVE2",
      //       jour: "mercredi",
      //       creneau: "18h00/19h00",
      //       description: "description day 2",
      //     },
      //     {
      //       ref: "MVE3",
      //       jour: "jeudi",
      //       creneau: "18h00/19h00",
      //       description: "description day 3",
      //     },
      //   ],
      // },
      // {
      //   location: "Les Grès",
      //   adresse: "",
      //   niveau: "Enfants",
      //   startAge: 7,
      //   endAge: 8,
      //   description: "description 2",
      //   ref: "GA1",
      //   readonly: false,
      //   days: [
      //     {
      //       ref: "GAE1",
      //       jour: "lundi",
      //       creneau: "20h00/21h30",
      //       description: "description day 1",
      //     },
      //     {
      //       ref: "GAE2",
      //       jour: "mercredi",
      //       creneau: "20h00/21h30",
      //       description: "description day 2",
      //     },
      //   ],
      // },
      // {
      //   location: "Les Grès",
      //   adresse: "",
      //   niveau: "Ados",
      //   startAge: 12,
      //   endAge: 15,
      //   description: "description 2",
      //   ref: "GA2",
      //   readonly: false,
      //   days: [
      //     {
      //       ref: "GAD1",
      //       jour: "lundi",
      //       creneau: "20h00/21h30",
      //       description: "description day 1",
      //     },
      //     {
      //       ref: "GAD2",
      //       jour: "mercredi",
      //       creneau: "20h00/21h30",
      //       description: "description day 2",
      //     },
      //   ],
      // },
    ],
    // A generer avec export excel programme python vers JSON ou Data base
    levelDataEnfant: [
      // {
      //   niveau: "Babys",
      //   startAge: 5,
      //   endAge: 6,
      //   NbTrainingByWeek: [1, 2],
      // },
      // {
      //   niveau: "Enfants",
      //   startAge: 7,
      //   endAge: 8,
      //   NbTrainingByWeek: [2],
      // },
      // {
      //   niveau: "Enfants",
      //   startAge: 9,
      //   endAge: 11,
      //   NbTrainingByWeek: [2],
      // },
      // {
      //   niveau: "Ados",
      //   startAge: 12,
      //   endAge: 15,
      //   NbTrainingByWeek: [2],
      // },
    ],
    // A generer avec export excel programme python vers JSON ou Data base
    priceDataEnfant: [
      // {
      //   niveau: "Babys",
      //   startAge: 5,
      //   endAge: 6,
      //   NbTrainingByWeek: 1,
      //   price: 195,
      // },
      // {
      //   niveau: "Babys",
      //   startAge: 5,
      //   endAge: 6,
      //   NbTrainingByWeek: 2,
      //   price: 250,
      // },
      // {
      //   niveau: "Enfants",
      //   startAge: 7,
      //   endAge: 8,
      //   NbTrainingByWeek: 2,
      //   price: 150,
      // },
      // {
      //   niveau: "Enfants",
      //   startAge: 7,
      //   endAge: 8,
      //   NbTrainingByWeek: 3,
      //   price: 200,
      // },
      // {
      //   niveau: "Enfants",
      //   startAge: 9,
      //   endAge: 11,
      //   NbTrainingByWeek: 2,
      //   price: 170,
      // },
      // {
      //   niveau: "Enfants",
      //   startAge: 9,
      //   endAge: 11,
      //   NbTrainingByWeek: 5,
      //   price: 270,
      // },
      // {
      //   niveau: "Ados",
      //   startAge: 12,
      //   endAge: 15,
      //   NbTrainingByWeek: 1,
      //   price: 195,
      // },
      // {
      //   niveau: "Ados",
      //   startAge: 12,
      //   endAge: 15,
      //   NbTrainingByWeek: 2,
      //   price: 205,
      // },
    ],

    // A generer avec export excel programme python vers JSON ou Data base
    paiementModaliteDataEnfant: [],
  },
  getters: {
    getPaiementModaliteDataEnfant: (state) => {
      return state.paiementModaliteDataEnfant;
    },
    getIncrementblockEnfant: (state) => {
      return state.incrementblockEnfant;
    },
    getPersonnalInformationEnfant_: (state) => {
      return state.personnalInformationEnfant_;
    },
    getResetEnfant: (state) => {
      return state.resetEnfant;
    },
    getSaveInscriptionEnfant: (state) => {
      return state.saveInscriptionEnfant;
    },
    getHoraireDataEnfant: (state) => {
      return state.horaireDataEnfant;
    },
    getIncrementEnfant: (state) => {
      return state.incrementEnfant;
    },

    getLevelDataEnfant: (state) => {
      return state.levelDataEnfant;
    },
    getPriceDataEnfant: (state) => {
      return state.priceDataEnfant;
    },
  },
  actions: {
    deleteStateInformationEnfant({ commit }, datas) {
      commit("DELETE_STATE_INFORMATION_ENFANT", datas);
    },
    createStateInformationEnfant({ commit }) {
      commit("CREATE_STATE_INFORMATION_ENFANT");
    },
    incrementInformationEnfant({ commit }, datas) {
      commit("INCREMENT_INIT_BLOCK_ENFANT", datas);
    },
    setFormDisabled({ commit }, datas) {
      commit("SET_FORM_DISABLED", datas);
    },
    setValidFormEnfant({ commit }, datas) {
      commit("SET_VALID_FORM_ENFANT", datas);
    },
    setResetEnfant({ commit }, datas) {
      commit("SET_RESET_ENFANT", datas);
    },
    setSaveInscriptionEnfant({ commit }, datas) {
      commit("SET_SAVE_INSCRIPTION_ENFANT", datas);
    },
    incrementInitEnfant({ commit }) {
      commit("INCREMENT_INIT_ENFANT");
    },
    desincrementInitEnfant({ commit }) {
      commit("DESINCREMENT_INIT_ENFANT");
    },
    setReglageEnfant({ commit }, datas) {
      commit("SET_REGLAGE_ENFANT", datas);
    },
  },
  mutations: {
    SET_REGLAGE_ENFANT(state, datas) {
      state.horaireDataEnfant = datas.horaireData;
      state.levelDataEnfant = datas.levelData;
      state.priceDataEnfant = datas.priceData;
      state.paiementModaliteDataEnfant = datas.paiementData;
    },
    DELETE_STATE_INFORMATION_ENFANT(state, datas) {
      // GOOD
      let index = datas.number;
      // console.log("index delete", index);
      // console.log(
      //   "index delete state",
      //   state.enfantBlock[index - 1]["personnalInformationEnfant_"][index]
      // );

      state.enfantBlock[index - 1]["personnalInformationEnfant_"][
        index
      ].show = false;
      // GOOD

      // state.enfantBlock.splice(index, 1);
      // state.incrementblockEnfant.init -= 1;

      // let index = state.enfantBlock.findIndex((el, key) => {
      //   return (
      //     `personnalInformationEnfant_${key + 1}` ==
      //     `personnalInformationEnfant_${datas.number + 1}`
      //   );
      // });

      // OLD
      // console.log(`personnalInformationEnfant_${datas.number + 1}`);
      // let index = state.enfantBlock.findIndex((el) => {
      //   return el.id == datas.number;
      // });
      // console.log("index delete", index);
      // console.log("datas number", datas.number);
      // state.enfantBlock.splice(index, 1);
      // OLD

      // state.enfantBlock[datas.number][
      //   `personnalInformationEnfant_${datas.number + 1}`
      // ].show = false;

      // state.enfantBlock[index][
      //   `personnalInformationEnfant_${datas.number + 1}`
      // ].show = false;

      // state.enfantBlock.splice(index, 1);
      // state.enfantBlock.splice(datas.number, 1);
    },
    CREATE_STATE_INFORMATION_ENFANT(state) {
      // Structure
      let structure = {
        // id: state.incrementblockEnfant.init,
        show: true,
        invalid: true,
        civilite: null,
        name: "",
        firstname: "",
        birthdate: "",
        fileDataJustif: "",
        fileDataPicture: "",
        level: [],
        // creneau: [],
      };

      let Object = { personnalInformationEnfant_: {} };

      Object["id"] = state.incrementblockEnfant.init;
      Object.personnalInformationEnfant_[state.incrementblockEnfant.init] =
        structure;

      Vue.set(structure, `${"creneau"}`, []);

      Vue.set(
        Object.personnalInformationEnfant_,
        `${state.incrementblockEnfant.init}`,
        structure
      );

      Vue.set(
        state.enfantBlock,
        `${state.incrementblockEnfant.init - 1}`,
        Object
      );

      // this.enfantBlock[this.i - 1]["personnalInformationEnfant_"][
      //   this.i
      // ].creneau

      // console.log("Object", Object);
      // state.enfantBlock.push(Object);

      // Validation
      let validation = {
        information: true,
        piece: true,
        niveau: true,
        creneau: true,
      };

      let ObjectValidation = { formEnfant_: {} };
      ObjectValidation["id"] = state.incrementblockEnfant.init;
      ObjectValidation.formEnfant_[state.incrementblockEnfant.init] =
        validation;

      state.invalidFormBlockEnfant.push(ObjectValidation);
    },
    INCREMENT_INIT_BLOCK_ENFANT(state) {
      state.incrementblockEnfant.init += 1;
    },
    SET_VALID_FORM_ENFANT(state, datas) {
      if (datas.i === 0) {
        // IF ENFANT 0
        state.invalidFormEnfant.creneau =
          datas.creneau != undefined
            ? datas.creneau
            : state.invalidFormEnfant.creneau;

        state.invalidFormEnfant.niveau =
          datas.niveau != undefined
            ? datas.niveau
            : state.invalidFormEnfant.niveau;

        state.invalidFormEnfant.information =
          datas.information != undefined
            ? datas.information
            : state.invalidFormEnfant.information;

        state.invalidFormEnfant.piece =
          datas.piece != undefined
            ? datas.piece
            : state.invalidFormEnfant.piece;

        state.personnalInformationEnfant_[0].invalid =
          // state.incrementblockEnfant.invalid =
          !datas.information && !datas.niveau && !datas.creneau ? false : true;
        ////Pour rendre oblig piece jointe
        // !datas.information && !datas.piece && !datas.niveau && !datas.creneau
        //   ? false
        //   : true;

        // console.log("datas invalid", datas);
        // console.log(datas.information, datas.piece, datas.niveau);
        // console.log(datas.information, datas.niveau, datas.creneau);

        // console.log(
        //   "state.personnalInformationEnfant_[0].invalid",
        //   state.personnalInformationEnfant_[0].invalid
        // );
        // IF ENFANT 0
      } else {
        // IF ENFANT > 0
        state.invalidFormBlockEnfant[datas.i - 1].formEnfant_[datas.i].creneau =
          datas.creneau != undefined
            ? datas.creneau
            : state.invalidFormBlockEnfant[datas.i - 1].formEnfant_[datas.i]
                .creneau;

        state.invalidFormBlockEnfant[datas.i - 1].formEnfant_[datas.i].niveau =
          datas.niveau != undefined
            ? datas.niveau
            : state.invalidFormBlockEnfant[datas.i - 1].formEnfant_[datas.i]
                .niveau;

        state.invalidFormBlockEnfant[datas.i - 1].formEnfant_[
          datas.i
        ].information =
          datas.information != undefined
            ? datas.information
            : state.invalidFormBlockEnfant[datas.i - 1].formEnfant_[datas.i]
                .information;

        state.invalidFormBlockEnfant[datas.i - 1].formEnfant_[datas.i].piece =
          datas.piece != undefined
            ? datas.piece
            : state.invalidFormBlockEnfant[datas.i - 1].formEnfant_[datas.i]
                .piece;

        // OLD
        // state.invalidFormBlockEnfant[datas.i - 1]["formEnfant_"][
        //   datas.i
        // ].invalid

        state.enfantBlock[datas.i - 1]["personnalInformationEnfant_"][
          datas.i
        ].invalid =
          !datas.information && !datas.niveau && !datas.creneau ? false : true;
        //// Pour rendre oblig les pieces jointes
        // state.enfantBlock[datas.i - 1]["personnalInformationEnfant_"][
        //   datas.i
        // ].invalid =
        //   !datas.information && !datas.piece && !datas.niveau && !datas.creneau
        //     ? false
        //     : true;

        // IF ENFANT > 0
      }

      // Verif general pour i > 0
      if (datas.i > 0) {
        let invalid = false;
        // console.log("enfantBlock.length", state.enfantBlock.length);
        for (let i = 0; i < state.enfantBlock.length; i++) {
          if (state.enfantBlock[i]["personnalInformationEnfant_"][i + 1].show) {
            invalid =
              invalid ||
              state.enfantBlock[i]["personnalInformationEnfant_"][i + 1]
                .invalid;
          }
        }
        state.incrementblockEnfant.invalid = invalid;
        // console.log("invalid enfant view", state.incrementblockEnfant.invalid);
      }
    },
    SET_RESET_ENFANT(state, datas) {
      // console.log("reset", datas);
      state.resetEnfant = datas;
    },
    SET_SAVE_INSCRIPTION_ENFANT(state, datas) {
      if (datas.typeForm == "personnalInformationEnfant") {
        state.saveInscriptionEnfant["personnalInformationEnfant"] = datas.value;
      }
      if (datas.typeForm == "personnalInformation") {
        state.saveInscriptionEnfant["personnalInformation"] = datas.value;
      }

      // A set dans une fonction a part
      // if (datas.typeForm == "pieceJointe") {
      //   state.saveInscriptionEnfant["pieceJointe"] = datas.value;
      // }

      // if (datas.typeForm == "contactUrgence") {
      //   state.saveInscriptionEnfant["contactUrgence"] = datas.value;
      // }

      // if (datas.typeForm == "niveau") {
      //   state.saveInscriptionEnfant["niveau"] = datas.value;
      // }

      // if (datas.typeForm == "creneau") {
      //   state.saveInscriptionEnfant["creneau"] = datas.value;
      // }

      if (datas.typeForm == "cotisationTotal") {
        state.saveInscriptionEnfant["cotisationTotal"] = datas.value.total;
        state.saveInscriptionEnfant["personnalInformationEnfant"][
          "enfant_0"
        ][0]["price"] = datas.value.priceEnfant_0;

        datas.value.priceEnfants_all.forEach((element, index) => {
          let id =
            state.saveInscriptionEnfant["personnalInformationEnfant"][
              "enfants_all"
            ][index].id;
          state.saveInscriptionEnfant["personnalInformationEnfant"][
            "enfants_all"
          ][index]["personnalInformationEnfant_"][id]["price"] = element;
        });
      }

      if (datas.typeForm == "moyens") {
        // state.saveInscriptionEnfant["moyens"] = datas.value.paiement;
        // state.saveInscriptionEnfant["ref"] = datas.value.ref;
        state.saveInscriptionEnfant["optIn"] = datas.value.optIn;
        state.saveInscriptionEnfant["saison"] = datas.value.saison;
        state.saveInscriptionEnfant["type"] = datas.value.type;
      }

      if (datas.typeForm == "ref") {
        state.saveInscriptionEnfant["ref"] = datas.value.ref;
      }
      // console.log("saveInscriptionEnfant", state.saveInscriptionEnfant);
    },
    INCREMENT_INIT_ENFANT(state) {
      state.incrementEnfant.init =
        state.incrementEnfant.init <= state.incrementEnfant.max
          ? state.incrementEnfant.init + 1
          : state.incrementEnfant.init;
    },
    DESINCREMENT_INIT_ENFANT(state) {
      state.incrementEnfant.init = state.incrementEnfant.init - 1;
    },
  },
};
